@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;
    color: #B0B0B0;
}

.title {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 20px;
    margin-bottom: 40px;
}

/* Estilos para las imágenes superiores */
.images-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.images-container .single-image {
    width: calc((100% - 2 * 20px) / 3);
    height: 105px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Estilos para las imágenes junto al formulario */
.image-section .small-image {
    width: 100%;
    height: 50px; /* o el tamaño que desees */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;
}

.input-row > div {
    display: flex;
    flex-direction: column;
    width: calc((100% - 3 * 20px) / 4);
}

.form-container .input-row:last-child > div {
    width: calc((100% - 2 * 20px) / 3);
}

input[type="text"], input[type="date"], input[type="time"] {
    padding: 10px;
    border: 1px solid #82828240;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: #B0B0B0;
}

label {
    display: block;
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: #B0B0B0;
}

/* Añadido para estructurar form-container y image-section */
.content-section {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.form-container {
    flex: 1;
}

.image-section {
    width: 140px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-container, .small-images-container {
    float: left;
    width: calc(100% - 180px - 20px); /* Restamos el ancho de la sección de imágenes pequeñas y un pequeño margen */
    box-sizing: border-box; /* Para que el padding y el border se incluyan en el ancho total */
}

.small-images-container {
    float: right;
    width: 85px;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre las imágenes */
}

.small-image {
    height: 45%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center; /* Centra el SVG verticalmente */
    justify-content: center; /* Centra el SVG horizontalmente */
}

.search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.search-bar {
    width: 637px;
    height: 20px;
    border-radius: 10px;
    background: rgba(80, 74, 87, 1) !important;
    padding: 10px 15px;
    border: none;
    outline: none;

}

.circular-button {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background: linear-gradient(to right, #F600C0 0%, #4409A4 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.circular-button:active, .circular-button:focus {
    outline: none;
}

.circular-button .plus-icon {
    font-size: 65px; /* Puedes ajustar según tus necesidades */
    color: white;
}

.table-container {
    width: 100%;
    height: 168px;
    background-color: rgb(38,23,58);
    overflow: auto;
  }
  
  .table-header, .table-row {
    display: flex;
    justify-content: space-between;
  }
  
  .table-header {
    font-size: 11px;
    color: #F4F4F4;
    border-bottom: 1px solid #F4F4F4;
  }
  
  .table-row {
    font-size: 11px;
    color: #F4F4F4;
  }
  
  .column {
    flex: 1;
    padding: 8px;
  }
  
  .icon {
    margin: 0 5px;
  }
  

.modal-content {
    width: 1100px;
    height: 865px;
    background-color: #fff;
    margin: auto;
    padding: 20px;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-size: 38px;
    font-weight: bold;
  }
  
  .close-button {
    font-size: 38px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}


.modal {
    /* ... previo código ... */
    width: 1100px;
    height: 90%;
    display: flex;
    flex-direction: column;
    background: rgb(38,26,58);
    border-radius: 53px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

h2 {
    font-size: 32px; /* Reducimos el tamaño de la fuente */
    font-weight: bold;
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 32px; /* Tamaño igual al del título */
    color: white; /* Color blanco */
    font-weight: bold; /* Texto en negrita */
    cursor: pointer;
}

.modal-sections {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.modal-section {
    display: flex;
    flex: 1;
}

.input-section {
    flex: 1;
    display: flex;
    width: 33%;
    flex-direction: column;
    justify-content: end;
    padding: 10px;
    margin: 30px 10px;
}

 /*.camera-section {
    flex: 1;
    border: 1px solid #ccc;
    Puedes agregar un código para mostrar la cámara del dispositivo aquí. 
}*/

.button-section {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 10px;
}


.camera-section {
    position: relative;  /* Esto permite que los hijos posicionados absolutamente se relacionen con este contenedor */
    margin: 30px;
    width: 33%; /* Establece el ancho restando los márgenes */
    overflow: hidden; /* Esto es para que, si hay contenido extra grande, se corte en vez de desbordar el contenedor */
}

video, canvas {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: contain; 
}

.capture-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
}


.register-button {
    background: linear-gradient(to right, #F600C0 0%, #4409A4 100%);
    border: none;
    border-radius: 4px;
    color: white;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    margin-left: 0;
    outline: none;
    font-size: 16px;
    border-radius: 35px;
    margin-bottom: 10px;
  }


.cancel-button {
    background: transparent;
    border: solid 1px #DADADA;
    color: white;
    width: 150px;
    height: 45px;
    text-align: center;
    font-size: 16px;
    border-radius: 35px;
    margin: 0px 20px 10px 10px;
  }
  

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    padding: 63px 0px;
    background: rgb(38,26,58);
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    width: 600px;
    border-radius: 32px;
}

.loading-icon {
    display: block; /* Cambia esto para que siempre se muestre (solo para pruebas) */
    position: fixed; /* Usaremos fixed para cubrir toda la pantalla */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centramos el elemento */
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Lo hacemos circular */
    border: 5px solid transparent; /* Tamaño del borde y transparente */
    border-top-color: #333; /* Color del sector superior del círculo */
    animation: spin 1s linear infinite; /* Animación */
    z-index: 9999; /* Asegurémonos de que esté al frente */
    background-color: rgba(255, 255, 255, 0.7); /* Le daremos un fondo semi-transparente para que se destaque */
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.register-button[disabled] {
    opacity: 0.6;           /* Hace que el botón sea ligeramente transparente */
    color: #888;            /* Cambia el color del texto a un gris más claro */
    /* ... cualquier otro estilo que desees agregar */
}

.regexi{
    color: #05A660;
    font-size: 25px;
    font-weight: bold;
}

.check-icon {
    display: inline-block;
    width: 50px;  /* Tamaño del círculo */
    height: 50px;
    border-radius: 50%;  /* Esto hace el círculo */
    background-color: green;
    position: relative;  /* Permitirá posicionar el check dentro del círculo */
    margin-bottom: 20px;  /* Espacio entre el ícono y el texto */
}

.check-icon::before {
    content: '✓';  /* Carácter de check */
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);  /* Esto centra el check en el círculo */
    color: white;
    font-size: 24px;  /* Tamaño del check */
}

.input-container {
    position: relative;
    display: inline-block;
}

.input-icon {
    position: absolute;
    left: 10px;  /* Cambia según el espaciado que necesites */
    top: 20px;
    transform: translateY(-50%);
    color: white;  /* o cualquier color que desees */
}

.linput {
    padding-left: 30px !important;  /* Esto da espacio para el ícono dentro del input */
}

.textoCamra{
    position: absolute;
    top: 0px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    background: black;
    margin: 0px;
    padding: 4px 0px;
}

.prelimchose{
    z-index: 1;
    position: absolute;
    bottom: 0;
}

.buttonConfiguracion{
    background: transparent;
    border: none;
    font-size: 23px;
}

.labelPop{
    font-size: 15px;
    color: white;
}

.btnCamara{
    display: inline;
    width: 220px;
    margin-top: 23px;

    background: linear-gradient(to right, #F600C0 0%, #4409A4 100%);
    border: none;
    border-radius: 4px;
    color: white;
    height: 45px;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    border-radius: 35px;
    margin-bottom: 10px;

}

.btn2{
    background: transparent;
    border: solid 2px gray;
    border-radius: 30px;
    padding: 9px 30px;
    color: white;
}